import styles from './SpeechBorder.css'

export function SpeechBorder({ weight = 'normal', layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)} role='presentation'>
      {weight === 'thin'
        ? <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" width="377" height="41" fill="none" viewBox="0 0 377 41"><path fill="#F06B0A" d="M3.145 2.645 204.488 0l168.81 1.893L377 20l-144.141-2.17-137.97.72L0 17.078 3.145 2.645Z" /><path fill="#F06B0A" d="M45.745 38.481 46.968 4.74 79.83 6.645 45.745 38.48Z" /></svg>
        : <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" width="247" height="42" viewBox="0 0 247 42" fill="none">
          <path d="M2.06065 17.5019L133.975 20.147L244.575 18.2537L247 0.146973L152.563 2.31754L62.1682 1.59632L0 3.07026L2.06065 17.5019Z" fill="currentColor" />
          <path d="M47.7436 38.8405L48.9666 5.09854L81.8289 7.004L47.7436 38.8405Z" fill="currentColor" />
        </svg>
      }
    </div>
  )
}
