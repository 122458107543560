import styles from './Heading.css'

export function Heading62({ h = 1, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={62} {...{ h, layoutClassName, title }} />
  )
}

export function Heading58({ h = 1, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={58} {...{ h, layoutClassName, title }} />
  )
}

export function Heading48({ h = 1, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={48} {...{ h, layoutClassName, title }} />
  )
}

export function Heading42({ h = 2, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={42} {...{ h, layoutClassName, title }} />
  )
}

export function Heading36({ h = 2, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={36} {...{ h, layoutClassName, title }} />
  )
}

export function Heading32({ h = 3, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={32} {...{ h, layoutClassName, title }} />
  )
}

export function Heading24({ h = 3, layoutClassName = undefined, title }) {
  return (
    <HeadingBase size={24} {...{ h, layoutClassName, title }} />
  )
}

function HeadingBase({ h, size, layoutClassName, title }) {
  /** @type {any} */
  const HBase = h ? `h${h}` : 'strong'
  const sizeClassName = styles[`component${size}`]

  return (
    <HBase className={cx(styles.componentBase, sizeClassName, layoutClassName)}>{title}</HBase>
  )
}
