import { Page } from '/features/pageOnly/Page'
import { ContentContainer } from '/features/pageOnly/ContentContainer'
import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonLink, Button } from '/features/buildingBlocks/Button'
import AppLogo from '/images/logos/helder-in-gesprek-logo.raw.svg'
import AboardLogo from '/images/logos/aboard-logo.raw.svg'
import { Heading48, Heading42 } from '/features/buildingBlocks/Heading'
import { SpeechBorder } from '/features/buildingBlocks/SpeechBorder'
import { IconArrowDown, IconBoekje, IconHulpNodig, IconPad, IconPrivacy, IconWaarom } from '/icons'
import { routeMap } from '/routeMap'
import { IS_STARTED_KEY, useLocalStorage } from '/machinery/useLocalStorage'

import styles from './AboutPage.css'

export function AboutPage() {
  const [isStarted, setIsStarted] = useLocalStorage({ key: IS_STARTED_KEY, fallbackValue: false })

  return (
    <Page>
      <header className={styles.header}>
        <ContentContainer>
          <Icon icon={AppLogo} layoutClassName={styles.appLogoLayout} />
        </ContentContainer>
      </header>

      <ContentContainer>
        <div className={styles.content}>
          <ButtonLink href='#hoe-werkt-het' color="blue" icon={IconArrowDown} layoutClassName={styles.forwardButtonLayout}>Hoe werkt het?</ButtonLink>

          <section className={styles.introduction}>
            <p>
              Helder in Gesprek is ontwikkeld om de communicatie tussen patiënt en zorgverlener te verbeteren. Want hoe beter we u begrijpen, hoe betere zorg we kunnen bieden.
            </p>
            <Button onClick={handleStart} layoutClassName={styles.overviewButtonLayout}>{isStarted ? 'Verder met invullen' : 'Direct beginnen'}</Button>
            <p>
              Helder in Gesprek is ontwikkeld met patiënten, naasten en zorgverleners binnen het onderzoek van Amsterdam UMC en het ABOARD project.
            </p>
            <div className={styles.aboardLogo}>
              <Icon icon={AboardLogo} />
            </div>
          </section>

          <section id='hoe-werkt-het' className={styles.explanationContent}>
            <div className={styles.explanationIntro}>
              <Heading48 h={2} title="HOE WERKT HET?" />
              <SpeechBorder weight='thin' />
              <p>
                U heeft binnenkort een gesprek met uw zorgverlener. Dit hulpmiddel helpt u om dat gesprek voor te bereiden.
              </p>
            </div>
            <div className={styles.topics}>
              <Topic icon={IconWaarom} title='Waarom dit hulpmiddel?'>
                <p>
                  U heeft vast meerdere onderwerpen die u wilt bespreken.
                </p>
                <p>
                  Door dit in te vullen, wordt duidelijk wat voor u de meest belangrijke onderwerpen zijn.
                </p>
              </Topic>
              <Topic icon={IconPad} title='Hoe werkt het?'>
                <p>
                  Aan de hand van stellingen brengen we uw dagelijks leven in beeld.
                </p>
                <p>
                  Stellingen die op u van toepassing zijn, kunt u nader toelichten.
                </p>
              </Topic>
              <Topic icon={IconBoekje} title='Na het invullen'>
                <p>
                  Aan het einde ontvangt u een samenvatting om mee te nemen naar uw gesprek.
                </p>
                <p>
                  Zo heeft u alles wat voor u belangrijk is, in één overzicht.
                </p>
              </Topic>
              <Topic icon={IconHulpNodig} title='Heeft u hulp nodig bij het invullen?'>
                <p>
                  U mag altijd een naaste vragen om het met u in te vullen.
                </p>
                <p>
                  Bijvoorbeeld degene die met u meegaat naar het gesprek met uw zorgverlener.
                </p>
              </Topic>
              <Topic icon={IconPrivacy} title='Wat gebeurt er met uw gegevens?'>
                <p>
                  Uw gegevens worden anoniem opgeslagen, en zijn niet terug te leiden naar u als persoon. <a href={routeMap.app.cookiePolicy({ language: 'nl' })} data-x='manage-cookies' className={styles.textLink}>Lees meer over cookies</a>
                </p>
              </Topic>
            </div>
            <Button onClick={handleStart} layoutClassName={styles.overviewButtonLayout}>{isStarted ? 'Verder met invullen' : 'Begin met invullen'}</Button>
          </section>

          <section className={styles.contactSection}>
            <Heading48 h={2} title='CONTACT' />
            <SpeechBorder weight='thin' />
            <p>
              Heeft u vragen of opmerkingen over dit hulpmiddel?
              Stuur dan een e-mail naar <a href="mailto:ikdenkmee@amsterdamumc.nl" className={styles.textLink}>ikdenkmee@amsterdamumc.nl</a>.
            </p>
          </section>
        </div>
      </ContentContainer>
    </Page>
  )

  function handleStart() {
    setIsStarted(true)
    window.location.href = routeMap.app.home({ language: 'nl' })
  }
}

function Topic({ icon, title, children }) {
  return (
    <div className={styles.componentTopic}>
      <div className={styles.topicIcon}>
        <Icon {...{ icon }} />
      </div>
      <Heading42 {...{ title }} layoutClassName={styles.topicTitleLayout} />
      {children}
    </div>
  )
}
