import { oneOf, node, string } from 'prop-types'

import styles from './Page.css'

Page.propTypes = {
  bgColor: oneOf(['white', 'blue']),
  children: node,
  className: string,
}

export function Page({ bgColor = 'white', children, className = undefined }) {
  return (
    <div className={cx(className, styles.component, bgColor && styles[bgColor])}>{children}</div>
  )
}
