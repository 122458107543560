import { oneOf, node } from 'prop-types'

import styles from './CenteredContainer.css'

CenteredContainer.propTypes = {
  size: oneOf(['xs', 'sm', 'md', 'lg']),
  children: node
}

export function CenteredContainer({ children, size = 'sm' }) {
  return (
    <div className={styles.component}>
      <div className={styles[size]}>{children}</div>
    </div>
  )
}
